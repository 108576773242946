import { defineStore } from 'pinia';
import { useGlobalContentStore } from './globalContent';
import { useCartStore } from './cart';
import { PurchaseType, IProductVariant } from '~~/models/api-types';
import * as Sentry from '@sentry/vue'

export const useVoyadoStore = defineStore('voyado', {
  state: () => ({
    contactId: null as string | null,
  }),
  actions: {
    async updateCart(purchaseType: string) {
      const config = useGlobalContentStore().config;
      const cartStore = useCartStore();
      if ((window as any).va) {
        const cart =
          purchaseType === PurchaseType.Subscription
            ? cartStore.subscriptionCart
            : cartStore.cart;

        return (window as any).va('cart', {
          cartRef: cart.id,
          contactId: this.contactId,
          cartUrl: cart.abandondedCartUrl,
          locale: config.currentMarket?.cultureCode,
          items: cart.items.map((item) => ({
            itemId: item.partNo,
            quantity: item.quantity,
          })),
        });
      }
    },
    async setContactIdFromEclubQueryParam(eclub: string): Promise<void> {
      const config = useGlobalContentStore().config;
      const { apiPost } = useApiFetch();

      if ((window as any).va) {
        const contactId = await apiPost<string>(
          `voyado/contactId/eclub?countryCode=${config.currentMarket?.countryCode}&eclub=${eclub}`
        );
        this.setContactId(contactId);
      }
    },
    async setContactIdFromEmail(email: string): Promise<void> {
      const config = useGlobalContentStore().config;
      const { apiPost } = useApiFetch();
      if ((window as any).va) {
        try {
          const contactId = await apiPost<string>(
            `voyado/contactId?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
            {
              email,
            }
          );
          this.setContactId(contactId);
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    },
    setContactId(contactId: string): void {
      if ((window as any).va) {
        (window as any).va('setContactId', contactId);
        this.contactId = contactId;
      }
    },
    sendProductView(product: IProductVariant): void {
      const config = useGlobalContentStore().config;
      if ((window as any).va) {
        (window as any).va('productview', {
          categoryName: product.gtmProperties.category,
          itemId: product.partNo,
          contactId: this.contactId,
          locale: config.currentMarket?.cultureCode
        });
      }
    },
    emptyCart(cartRef): void {
      if ((window as any).va) {
        (window as any).va('emptyCart', {
          cartRef,
          contactId: this.contactId,
        });
      }
    },
  },
});
